import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Upnext from "../components/upnext"
import ContentLayout from "../components/contentLayout"

const Text = () => {
  return (
    <>
      <h1 className="text-brand text-base uppercase tracking-wider font-bold">
        About Redgarden
      </h1>
      <h2 className="my-10 mb-10 text-4xl font-bold leading-snug">
        Creating memorable connections through organic ideas
        <span className="text-brand">.</span>
      </h2>

      <p className="mb-0">
        We’re a forward-thinking, boutique creative and digital agency based in
        Auckland, New Zealand.
      </p>

      <span className="inline-block my-6 text-2xl text-brand font-bold leading-snug">
        We stand firm in our beliefs
      </span>

      <p className="mb-6">
        We're all about transparency, we work with you from start to finish so
        you're not left in the dark. Authenticity is important, your business
        should stand above the rest in the message you send through your brand.
        We believe that it's our ethical responsibility to develop a brand that
        your customers can trust.
      </p>

      <h4 className="mb-2 font-bold text-sm uppercase text-brand">Expertise</h4>
      <p className="mb-6">
        We use the right tools for the job. We're up-to-date with the latest
        innovations so that your work is future-proof and performs the best it
        can.
      </p>

      <h4 className="mb-2 font-bold text-sm uppercase text-brand">Honesty</h4>
      <p className="mb-6">
        We'll always have your best interests at heart. We're accurate and
        honest from start to finish.
      </p>

      <h4 className="mb-2 font-bold text-sm uppercase text-brand">Quality</h4>
      <p className="mb-6">
        We're passionate about quality. We believe that even the finer details
        matter.
      </p>
      <p className="mb-6">
        We strive to solve problems creatively. We create engaging experiences
        that build long-standing relationships with your customers.
      </p>
    </>
  )
}

const About = ({ location }) => {
  return (
    <Layout
      location={location}
      header={`dark`}
      image={`https://images.unsplash.com/photo-1511649475669-e288648b2339?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1778&q=80`}
    >
      <SEO title="About Us" />

      <ContentLayout
        featureImage={`https://images.unsplash.com/photo-1523465219434-53d659a92880?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80`}
      >
        <Text />
      </ContentLayout>
      <Upnext to={"our-services"} text={"Learn about our services"} />
    </Layout>
  )
}

export default About
